import excelParser from '../../../../services/excel-parser'
import LocationPreProcessorService from '../../../../services/notisphere/locationpreprocessor'
import _ from 'lodash'
import VueDropzone from 'vue2-dropzone'
export default {
    data() {
        return {
            info: {

            },
            InputFileId: 0,
            selectedColumns: [],
            preProcessorId: 0,
            jobname: '',
            comments: '',
            suppliername: '',
            filesUpload: [],
            thumbnailIndex: -1,
            item: {},
            bytefile: '',
            isFileSelect: false,
            disabled: false,
            dropzoneOptions: {
                url: '#',
                acceptedFiles: '.xlsx',
                maxFiles: 1,
                maxFilesize: 20,
                previewsContainer: false,
                autoProcessQueue: false,
                clickable: true
            },
            routeFormType: '',
            btnName: 'Save & Close'
        }
    },
    components: {
        VueDropzone
    },
    mounted() {
        this.info = _.cloneDeep(this.infoData)
        this.thumbnailIndex = this.filesUpload.findIndex(x => !!x.is_checked)
    },
    created() {
        if (this.$route.params && this.$route.params.id) {
            this.initialLoading = true
            this.routeFormType = 'EDIT'
            this.fetchjobDetail(this.$route.params.id).then(res => {
                if (res) {
                    this.initialLoading = false
                }
            })
        } else {
            this.routeFormType = 'CREATE'
        }

    },

    computed: {
        isRequired() {
            if (this.infoData && ['OPEN', 'CLOSED'].includes(this.infoData.status)) {
                return true
            } else {
                return false
            }
        },
        isDisabled() {
            if (this.routeFormType == '') {
                if ((this.jobname == null || this.jobname.trim() == '') || this.suppliername == null || this.suppliername.trim() == '') {
                    return true
                }
                else {
                    if (this.filesUpload.filter(s => s.is_checked == true).length == 0) {
                        return true
                    }
                    else {
                        return false
                    }

                }
            }
            if (this.routeFormType == 'CREATE' ||this.routeFormType=='EDIT') {
                if ((this.jobname == null || this.jobname.trim() == '') || this.suppliername == null || this.suppliername.trim() == '') {
                    return true
                }
                else {
                    if (this.isFileSelect == true) {
                        return false
                    }
                    else {
                        return true
                    }
                }
            }
        }
    },

    methods: {
        fetchjobDetail(id) {
            return new Promise((resolve,) => {
                LocationPreProcessorService.getLocationPreProcessorJobById(id).then(res => {
                    if (!res.error) {
                        this.jobname = res.data.d.name
                        this.comments = res.data.d.comment
                        this.suppliername = res.data.d.supplierName
                        this.preProcessorId = res.data.d.preProcessorId
                        this.InputFileId = res.data.d.inputFileId
                        this.selectedColumns=res.data.d.selectedCols
                        this.disabled=res.data.d.isSubmitted
                        if(this.disabled)
                        {
                            this.$refs.myVueDropzone.disable()
                        }
                        this.fetchselecteddocument()
                        if (!res.data.d.preProcessorId || res.data.d.preProcessorId === null) {
                            this._showToast('Invalid Job ID', { variant: 'danger' })
                            this.$router.push({ name: 'noti_Location_preProcessor' })
                        }
                    }
                })
            })

        },
        base64ToArrayBuffer(base64) {
            var binaryString = window.atob(base64)
            var binaryLen = binaryString.length
            var bytes = new Uint8Array(binaryLen)
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i)
                bytes[i] = ascii
            }
            return bytes
        },
        urltoFile(url, filename, mimeType) {
            return (fetch(url)
                .then(function (res) { return res.arrayBuffer() })
                .then(function (buf) { return new File([buf], filename, { type: mimeType }) })
            )
        },
        fetchselecteddocument() {
            LocationPreProcessorService.getDocumentById(this.InputFileId).then(resp => {
                if (!resp.error) {
                    resp.data.d.path = resp.data.d.signed_url
                    resp.data.d.name = resp.data.d.name + resp.data.d.extension
                    this.item.file = resp.data.d
                    if(resp.data.d.column_headers != null){
                        resp.data.d.column_headers.forEach(x=>{
                            const filtered = this.selectedColumns.filter((email) => {
                                return email !=''
                            })
                            let ischecked =filtered.includes(x.replace(',',''))
                            if(x != null && x !=''){
                                this.filesUpload.push({
                                    Name: x,
                                    is_checked: ischecked
                                })
                            }

                        })
                        if (this.filesUpload.filter(s => s.is_checked == true).length > 0) {
                            this.btnName = 'Submit job'
                        }
                        else {
                            this.btnName = 'Save & Close'
                        }
                        this.isFileSelect = true
                    }
                }
            })
        },
        addSuccess(file) {
            this.isFileSelect = false
            this.filesUpload = []

            if (file.name && _.isArray(file.name.split('.'))) {
                var ext = file.name.split('.')
                if (['xlsx'].includes(ext[ext.length - 1])) {
                    if (file.size > 100 * (1024 * 1024)) {
                        this._showToast('The max size limit should be 100 MB', { variant: 'danger' })
                    }
                    else {
                        this.item.file = file
                        try {
                            let formData = new FormData()
                            formData.append('file', this.item.file)
                            excelParser.getExcelHeaders(formData).then(res=>{
                                if(!res.error){
                                    res.data.d.forEach (x=>{
                                        this.filesUpload.push({
                                            Name: x,
                                            is_checked: false
                                        })
                                    })
                                    this.btnName = 'Save & Close'
                                    this.isFileSelect = true
                                    this._showToast('File uploaded successfully', { variant: 'success' })
                                }
                            })

                        } catch (e) { console.log(e) }
                    }

                } else {
                    this._showToast('The file extension should be .xlsx.', { variant: 'danger' })
                }
            }

        },
        handleCheckedValue(val, i) {
            this.thumbnailIndex = val
            this.filesUpload.forEach((x, index) => {
                if (index === i) {
                    if (!x.is_checked) {
                        x.is_checked = false
                    } else {
                        x.is_checked = true
                    }
                }
            })
            if (this.filesUpload.filter(s => s.is_checked == true).length > 0) {
                this.btnName = 'Submit job'
            }
            else {
                this.btnName = 'Save & Close'
            }
        },
        saveAndGo() {
            return new Promise((resolve, reject) => {
                this.$refs['observer'].validate().then(isValid => {
                    if (isValid) {
                        this.comments = this.comments == null ? '' : this.comments
                        let formData = new FormData()
                        formData.append('preProcessorId', this.preProcessorId)
                        formData.append('name', this.jobname)
                        formData.append('comment', this.comments)
                        formData.append('suppliername', this.suppliername)
                        formData.append('file', this.item.file)
                        formData.append('bytefile', this.bytefile)
                        this.filesUpload.filter(s => s.is_checked).forEach((x, i) => {
                            formData.append('filesUpload[' + i + ']', x.Name)
                        })
                        LocationPreProcessorService.not_createPreprocessorjob(formData).then(res => {
                            if (!res.error) {
                                if (this.filesUpload.filter(s => s.is_checked == true).length > 0) {
                                    this._showToast('Your job has been submitted successfully', { variant: 'success' })
                                }
                                else {
                                    this._showToast('Your job has been saved successfully.', { variant: 'success' })
                                }
                                this.$router.push({ name: 'noti_Location_preProcessor' })
                                resolve(res.data.d)
                            }
                        })
                    } else {
                        reject('Invalid')
                    }
                })
            })
        }
    }
}